export default {
  meta_title: 'Education — PennyLane',
  meta_description:
    'Teach quantum programming with PennyLane! Empower your students with innovative resources to shape the next generation of quantum professionals.',
  meta_image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/education/og_image.png',

  /* Hero Section */
  heroSection:{
    /* Hero Main */
    title: 'Teach quantum programming with PennyLane',
    description:
      'PennyLane’s resources empower educators to create innovative learning opportunities for their students; helping shape the next generation of quantum professionals.',
    image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/education/hero/education-hero-image.jpg',
    ctas: {
      /* Appears as a button */
      primary: {
        text: 'Get started',
        link: '#get-in-touch',
      },
      /* Appears as a cta style link "text ->"*/
      secondary: {
        text: 'See our partners',
        link: '#education-partners',
      },
    },
  },

  /* Why Educators choose PennyLane */
  whyEducatorsSection: {
    title: 'Why educators and students love PennyLane',
    /* Limit to 3 cards */
    cards: [
      {
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/icons/easy_yo_use.png',
        title: 'Engaging content curated by a team of experts.'
      },
      {
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/icons/large_library.png',
        title: 'Large library of interactive quantum resources.'
      },
      {
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/icons/community_support.png',
        title: 'Supported by an active online community.'
      },
    ],
    description: "",
    cta: {
      text: 'Get in touch',
      link: '#get-in-touch',
    },
  },

  /* Education Partners */
  educationPartners: {
    title: 'Our education partners',
    /**
     * IMPORTANT:
     * Please sort the partners alphabetically by name
     */
    partners: [
      { name: 'Abu Dhabi University', logo: 'https://assets.cloud.pennylane.ai/external/universities/abu_dhabi.png' },
      { name: 'Hamad Bin Khalifa University (HBKU)', logo: 'https://assets.cloud.pennylane.ai/external/universities/habad_bin_khafia.png' },
      { name: 'Johns Hopkins University', logo: 'https://assets.cloud.pennylane.ai/external/universities/john_hopkins.png' },
      { name: 'KFUPM', logo: 'https://assets.cloud.pennylane.ai/external/universities/king_fahd.png' },
      { name: 'KAIST', logo: 'https://assets.cloud.pennylane.ai/external/universities/korea_advanced.png' },
      { name: 'Korea University', logo: 'https://assets.cloud.pennylane.ai/external/universities/korea.png' },
      { name: 'McMaster University', logo: 'https://assets.cloud.pennylane.ai/external/universities/mcmaster.png' },
      { name: 'Memorial University', logo: 'https://assets.cloud.pennylane.ai/external/universities/memorial.png' },
      { name: 'Missouri State University', logo: 'https://assets.cloud.pennylane.ai/external/universities/missouri_state.png' },
      { name: 'POSTECH', logo: 'https://assets.cloud.pennylane.ai/external/universities/postech.png' },
      { name: 'Queens University', logo: 'https://assets.cloud.pennylane.ai/external/universities/queens.png' },
      { name: 'Seoul National University', logo: 'https://assets.cloud.pennylane.ai/external/universities/seoul_national.png' },
      { name: 'Toronto Metropolitan University', logo: 'https://assets.cloud.pennylane.ai/external/universities/toronto_metropolitan.png' },
      { name: 'UCSB', logo: 'https://assets.cloud.pennylane.ai/external/universities/california_santa_barbara.png' }, 
      { name: 'University of Bari', logo: 'https://assets.cloud.pennylane.ai/external/universities/bari_also_moro.png' },
      { name: 'University of British Columbia', logo: 'https://assets.cloud.pennylane.ai/external/universities/british_columbia.png' },
      { name: 'University of Calgary', logo: 'https://assets.cloud.pennylane.ai/external/universities/calgary.png' },
      { name: 'University of Calabria', logo: 'https://assets.cloud.pennylane.ai/external/universities/calabria.png' },
      { name: 'University of Edinburgh', logo: 'https://assets.cloud.pennylane.ai/external/universities/edinburgh.png' },
      { name: 'University of Jyväskylä', logo: 'https://assets.cloud.pennylane.ai/external/universities/jyvaskyla.png' },
      { name: 'University of Malaya', logo: 'https://assets.cloud.pennylane.ai/external/universities/malaya.png' },
      { name: 'University of Ottawa', logo: 'https://assets.cloud.pennylane.ai/external/universities/ottawa.png' },
      { name: 'University of Sherbrooke', logo: 'https://assets.cloud.pennylane.ai/external/universities/sherbrooke.png' },
      { name: 'University of Toronto', logo: 'https://assets.cloud.pennylane.ai/external/universities/toronto.png' },
      { name: 'University of Victoria', logo: 'https://assets.cloud.pennylane.ai/external/universities/victoria.png' },
      { name: 'University of Waterloo', logo: 'https://assets.cloud.pennylane.ai/external/universities/waterloo.png' },
      { name: 'Yale University', logo: 'https://assets.cloud.pennylane.ai/external/universities/yale.png' }
    ]
  },

  /* Education Insights */
  educationInsights: {
    /* Insights - ordered from primary to tertiary */
    insights: {
      primary: {
        title: 'Countries learning with PennyLane',
        total: 34,
      },
      secondary: {
        title: "University collaborations",
        total: 121,
      },
      tertiary: {
        title: "Courses curated by our experts",
        total: 179,
      },
    },
    /* Map image */
    image: {
      alt: 'hot spots of all the countries PennyLane is being used in by education partners.',
      src: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/education/insights/map.png',
    }
  },


  /* Contact Form */
  getInTouchSection: {
    title: 'Get in touch with our experts',
    description:
      'Share your goals to help us tailor resources for your course.',
    dropdownOptions: [
      {
        label: 'Educator',
        value: 'Educator',
      },
      {
        label: 'Researcher',
        value: 'Researcher',
      },
      {
        label: 'Graduate student',
        value: 'Graduate student',
      },
      {
        label: 'Other',
        value: 'Other',
      },
    ],
    footnote: `By submitting, you are agreeing to our [privacy policy](/privacy).`,
    formSubmittedTitle: 'Thank you!',
    formSubmittedText: `A PennyLane team member will reach out shortly. In the meantime, explore
        our [learning resources](/qml)`,
    formErrorMessage: 'Something went wrong. Please try again later.',
    image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/education/contact/get_in_touch.png',
  },
  
  /* Educator Testimonials */
  testimonials_section: {
    quotes: [
      {
        quote:
          'Xanadu’s support and open-source materials provided our students with a unique opportunity to learn from real experts at the leading edge of quantum computing. Our partnership with Xanadu is truly producing quantum-ready graduates.',
        author: {
          name: 'Ray LaPierre',
          affiliation: 'McMaster University',
          image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/education/educators/ray_lapierre.png',
        },
      },
      {
        quote:
          `Xanadu's open-source tools, particularly PennyLane, have been instrumental in enhancing our course material. These resources allow students to explore quantum algorithms in a hands-on environment, accelerating their understanding and innovation in quantum computing.`,
        author: {
          name: 'Yufei Ding',
          affiliation: 'University of California San Diego',
          image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/education/educators/yufei_ding.png',
        },
      },
       {
        quote:
          'The interactive material shared by Xanadu helped me teach a course on introduction to quantum computer science. I have received overwhelming support from the team at Xanadu and would like to congratulate them for their amazing content.',
        author: {
          name: 'Ankur Raina',
          affiliation: 'IISER Bhopal',
          image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/education/educators/ankur_rania.png',
        },
      },
      {
        quote:
          'Using PennyLane for my quantum computing class was a game-changer. The comprehensive documentation and tutorials provided invaluable support, making complex quantum concepts accessible and easy to implement for students and educators.',
        author: {
          name: 'KC Kong',
          affiliation: 'University of Kansas',
          image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/education/educators/kong.jpg',
        },
      },
      {
        quote:
          'The PennyLane Codebook and PennyLane tutorials combine theoretical explanations with hands-on exercises on basic quantum computing topics and research-level topics, respectively, in a pedagogically excellent way. Our students have found these resources to be superbly useful for getting real practical experience in quantum computing.',
        author: {
          name: 'Matti Raasakka',
          affiliation: 'Aalto University',
          image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/education/educators/matti_raasakka.jpg',
        },
      },
    ],
  },
  
  /* Highlighted Educators */
  educatorsSection: {
    title: 'Quantum educators',

    /* Educators:
      * - Educators are sorted automatically by last name
      ---------------------------------------------------
      * - Links are optional
      * - Only pennylane profile, linkedin, and google scholar links are supported
      
    */
    educators: [
      {
        firstName: `Mahn-Soo`,
        lastName: `Choi`,
        affiliation: `Korea University`,
        image: `https://assets.cloud.pennylane.ai/pennylane_website/pages/education/educators/mahn_soo_choi.png`,
        links: {
          profile: ``,
          linkedin: ``,
          googleScholar: ``,
          other: 'https://www.semanticscholar.org/author/Mahn%E2%80%90Soo-Choi/39747292'
        },
      },
      {
        firstName: `Ray`,
        lastName: `LaPierre`,
        affiliation: `McMaster University`,
        image: `https://assets.cloud.pennylane.ai/pennylane_website/pages/education/educators/ray_lapierre.png`,
        links: {
          profile: ``,
          linkedin: ``,
          googleScholar: `https://scholar.google.ca/citations?user=3f2aGJ8AAAAJ`,
          other: ''
        },
      },
      {
        firstName: `Daniel Felipe`,
        lastName: `Nino`,
        affiliation: `University of Toronto`,
        image: `https://assets.cloud.pennylane.ai/pennylane_website/pages/education/educators/daniel_nino.jpg`,
        links: {
          profile: `https://pennylane.ai/profile/Daniel_X`,
          linkedin: `https://ca.linkedin.com/in/daniel-felipe-nino`,
          googleScholar: `https://scholar.google.com/citations?user=if_4Py0AAAAJ&hl=en`,
          other: ''
        }
      },
      {
        name: `Greg van Anders`,
        firstName: `Greg`,
        lastName: `van Anders`,
        affiliation: `Queen's University`,
        image: `https://assets.cloud.pennylane.ai/pennylane_website/pages/education/educators/greg_van_anders.png`,
        links: {
          profile: '',
          googleScholar: `https://scholar.google.com/citations?user=Y7PwqD8AAAAJ`,
          linkedin: ``,
          other: ''
        }
      },
      {
        firstName: 'Kyoungchul (KC)',
        lastName: 'Kong',
        affiliation: 'University of Kansas',
        image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/education/educators/kong.jpg',
        links: {
          profile: '',
          linkedin: '',
          googleScholar: 'https://scholar.google.com/citations?user=mHxha4QAAAAJ&hl=en',
          other: '',
        },
      },
      {
        firstName: 'Ankur',
        lastName: 'Rania',
        affiliation: 'IISER Bhopal',
        image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/education/educators/ankur_rania.png',
        links: {
          profile: '',
          linkedin: 'https://in.linkedin.com/in/ankur-raina-6968749',
          googleScholar: '',
          other: '',
        },
      },
      {
        firstName: 'Taehyun',
        lastName: 'Kim',
        affiliation: 'Seoul National University',
        image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/education/educators/taehyun_kim.png',
        links: {
          profile: '',
          linkedin: '',
          googleScholar: 'https://scholar.google.co.kr/citations?user=xLVkHSAAAAAJ&hl=en',
          other: '',
        },
      },
      {
        firstName: 'Yufei',
        lastName: 'Ding',
        affiliation: 'University of California San Diego',
        image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/education/educators/yufei_ding.png',
        links: {
          profile: '',
          linkedin: '',
          googleScholar: 'https://scholar.google.com/citations?user=MiPxo9UAAAAJ&hl=en',
          other: '',
        },
      },
      {
        firstName: `Marcelo`,
        lastName: `Ponce`,
        affiliation: `University of Toronto`,
        image: `https://assets.cloud.pennylane.ai/pennylane_website/pages/education/educators/ponce_marcelo.jpg`,
        links: {
          profile: ``,
          linkedin: `https://ca.linkedin.com/in/marcelo-ponce-360497`,
          googleScholar: `https://scholar.google.com/citations?user=CKWPbQEAAAAJ&hl=en`,
          other: ''
        },
      },
      {
        firstName: `Matti`,
        lastName: `Raasakka`,
        affiliation: `Aalto University`,
        image: `https://assets.cloud.pennylane.ai/pennylane_website/pages/education/educators/matti_raasakka.jpg`,
        links: {
          profile: ``,
          linkedin: `https://fi.linkedin.com/in/matti-raasakka`,
          googleScholar: ``,
          other: ''
        },
      },
    ]
  },

  /* Explore PennyLane CTA */
  explorePennyLane: {
    title: "Explore PennyLane",
    cards: [
      {
        title: "Demos",
        description: "Dive into the code and see how to apply cutting-edge algorithms and techniques with PennyLane.",
        icon: "https://assets.cloud.pennylane.ai/pennylane_website/icons/demos_icon.png",
        link: "/qml/demonstrations",
      },
      {
        title: "Challenges",
        description: "Get hands-on experience with quantum computing in PennyLane using our exclusive coding challenges.",
        icon: "https://assets.cloud.pennylane.ai/pennylane_website/icons/challenges_icon.png",
        link: "/challenges",
      },
      {
        title: "Codebook",
        description: "Use PennyLane to explore various quantum computing topics in the Quantum Codebook.",
        icon: "https://assets.cloud.pennylane.ai/pennylane_website/icons/codebook_icon.png",
        link: "/codebook",
      },
      {
        title: "Videos",
        description: "Follow along with our experts and explore ideas in quantum computing, quantum machine learning, and quantum chemistry.",
        icon: "https://assets.cloud.pennylane.ai/pennylane_website/icons/videos_icon.png",
        link: "/qml/videos",
      },
      {
        title: "Blog",
        description: "Keep up with the newest PennyLane features and events, while learning the latest in quantum programming from experts.",
        icon: "https://assets.cloud.pennylane.ai/pennylane_website/icons/Blog_Icon.png",
        link: "/blog",
      },
      {
        title: "Glossary",
        description: "A growing glossary of key concepts for quantum computing, quantum machine learning, and quantum chemistry.",
        icon: "https://assets.cloud.pennylane.ai/pennylane_website/icons/glossary_icon.png",
        link: "/qml/glossary",
      },
    ],
  },
}

import { IContentAuthor, IAuthor } from '../types'
import { determineProfileDescription, slugifyString } from 'shared-utilities'
import { getCurrentEnvConfig } from '../../config'

export const checkIfAuthorNameExists = (
  authors: IContentAuthor[] | IAuthor[] | string[],
  authorName: string
) => {
  // We have to cast authors as any in order to remove `This expression is not callable.` error
  // There is currently an issue open in the typescript GH repo addressing this
  // https://github.com/microsoft/TypeScript/issues/44373

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const author = (authors as any).find(
    (author: IAuthor | IContentAuthor | string) =>
      slugifyString(
        typeof author === 'string' ? author : author?.name || ''
      ) === slugifyString(authorName)
  )
  return author ? author : null
}

export const getAuthorObjectList = (authorData) => {
  if (!authorData) return []
  return authorData.map((author) => {
    const formattedBio = determineProfileDescription({
      headline: author.headline,
      about: author.about,
    })

    return {
      name: `${author.firstName || ''}${
        author.lastName ? ` ${author.lastName}` : ''
      }`,
      bio:
        formattedBio.length > 250
          ? `${formattedBio.substring(0, 250)}...`
          : formattedBio,
      image: author.avatarUrl || '',
      profileUrl: author.handle
        ? `${getCurrentEnvConfig().siteURL}/profile/${author.handle}`
        : '',
      username: author.handle || '',
    }
  })
}
